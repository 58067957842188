<template>
  <v-card class="mx-auto" width="300" color="transparent" elevation="0">
    <!--
      <v-list v-if="isInRole(['ServiceAdmin'])">
        <v-list-item :to="'/dashboard'">
          <v-list-item-icon><v-icon>mdi-chart-line</v-icon></v-list-item-icon>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>
      </v-list>
    -->
      <v-list v-if="isInRole(['WholeCompanyStatisticalData'])">
        <v-list-item :to="'/report'">
          <v-list-item-icon><v-icon>mdi-finance</v-icon></v-list-item-icon>
          <v-list-item-title>
            Report
          </v-list-item-title>
        </v-list-item>
      </v-list>
    <!--
      <v-list v-if="isInRole(['ServiceAdmin'])">
        <v-list-item href="https://techloud.myfamily.network">
          <v-list-item-icon><v-icon>mdi-printer</v-icon></v-list-item-icon>
          <v-list-item-title>Techloud</v-list-item-title>
        </v-list-item>
      </v-list>
    -->
    <!-- EDI -->
    <v-list v-if="isInRole(['ServiceAdmin'])">
      <v-list-item :to="'/edi'">
        <v-list-item-icon><v-icon>mdi-swap-horizontal</v-icon></v-list-item-icon>
        <v-list-item-title>EDI</v-list-item-title>
      </v-list-item>
    </v-list>

    <v-list>
      <v-list-group color="tertiary" :value="!mini" prepend-icon="mdi-hubspot" v-if="crmItemsFiltered.length">
        <template v-slot:activator>
          <v-list-item-title v-t="'crm'"></v-list-item-title>
        </template>

        <v-list-item v-for="(menuItem, i) in crmItemsFiltered" :key="i" :to="menuItem.to" :hidden="menuItem.hide">
          <v-list-item-icon><v-icon></v-icon></v-list-item-icon>
          <v-list-item-title>
            {{ menuItem.title }}
            <v-badge class="mx-2" v-if="!!menuItem.badge" color="red" outlined :content="menuItem.badge" :value="menuItem.badge"></v-badge>
          </v-list-item-title>
          <v-list-item-action v-show="hasOrderDrafts && menuItem.title==='Orders'">
            <v-icon color="warning">mdi-alert-circle</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-list-group no-action sub-group color="tertiary" v-if="gestioneFiltered.length">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-t="'management'"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(gest, i) in gestioneFiltered" :key="i" :to="gest.to">
            <v-list-item-title v-text="gest.title"></v-list-item-title>
          </v-list-item>
          <v-list-item v-for="(gest, i) in financialFiltered" :key="'qq'+i" :to="gest.to">
            <v-list-item-title v-text="gest.title"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list-group>
    </v-list>
    <v-list v-if="tenantCollectionDropdown.length > 1">
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item v-bind="attrs" v-on="on" >
            <v-list-item-icon><v-icon>mdi-airplane</v-icon></v-list-item-icon>
            <v-list-item-title>{{ tenantName }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2" v-t="'selectTenant'"></v-card-title>
          <v-card-text>
            <v-autocomplete v-model="temp.tenant" :items="tenantCollectionDropdown" :label="$t('tenant')" hide-details="auto" dense autocomplete="nope" outlined></v-autocomplete>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="travel()" v-t="'travel'"></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list>
  </v-card>
</template>


<script>
import { get, call, sync } from 'vuex-pathify';

export default {
  props: ['mini'],
  computed: {
    hasOrderDrafts: get("orders/hasOrderDrafts"),
    crmItemsFiltered() {
      return this.crmItems.filter(el => this.isInRole(el.minRoles));
    },
    gestioneFiltered() {
      return this.gestione.filter(el => this.isInRole(el.minRoles));
    },
    priceCatalogsFiltered() {
      return this.priceCatalogs.filter(el => this.isInRole(el.minRoles));
    },
    tenantCollectionDropdown: get('tenantConfiguration/tenantCollectionDropdown'),
    tenantName: get('tenantConfiguration/tenantName'),
    showPaymentMethods: get('tenantConfiguration/showPaymentMethods'),
    showTNT: get('tenantConfiguration/showTNT'),
    crmItems(){
      return [
        { title: this?.$t('orders') ?? "Orders", to: '/orders' },
        { title: this?.$t('ordersContent') ?? "OrdersContent", to: '/order-row-content' },
        { title: this?.$t('agents') ?? "Agents", to: '/agents', minRoles: ['SupervisorAgent', 'CommercialSpecialist'] },
        { title: this?.$t('customers') ?? "Customers", to: '/customers' },
        { title: this?.$t('monitoring') ?? "Monitoring", to: '/customer-monitoring', minRoles: ['SupervisorAgent', 'CommercialSpecialist'] },
        { title: this?.$t('budget') ?? "Budget", to: '/budget', minRoles: ['BudgetManager'] },
        { title: 'TNT', to: '/tnt', hide: !this.showTNT },
      ]
    },
    gestione(){
      return [
        { title: this?.$t('items') ?? "Items", to: '/items', minRoles: ['SupervisorAgent', 'CommercialSpecialist'] },
        { title: this?.$t('family') ?? "Family", to: '/brands', minRoles: ['SupervisorAgent', 'CommercialSpecialist'] },
        { title: this?.$t('collection') ?? "Collection", to: '/collections', minRoles: ['SupervisorAgent', 'CommercialSpecialist'] },
        { title: this?.$t('group') ?? "Group", to: '/groups', minRoles: ['SupervisorAgent', 'CommercialSpecialist'] },
        { title: this?.$t('kit') ?? "Kit", to: '/kits', minRoles: ['SupervisorAgent', 'CommercialSpecialist'] },
        { title: this?.$t('saleTaxes') ?? "Sale Taxes", to: '/vats', minRoles: ['SupervisorAgent', 'CommercialSpecialist'] },
      ]
    },
    financialFiltered(){
      return !!this.showPaymentMethods ? [
        { title: this?.$t('paymentTerms') ?? "Payment Terms", to: '/payment-terms', minRoles: ['SupervisorAgent', 'CommercialSpecialist'] },
        { title: this?.$t('paymentMethods') ?? "Payment Methods", to: '/payment-methods', minRoles: ['SupervisorAgent', 'CommercialSpecialist'] },
        { title: this?.$t('priceCatalogs') ?? "Price Catalogs", to: '/load-price-catalog', minRoles: ['SupervisorAgent', 'CommercialSpecialist'] },
      ] : [
        { title: this?.$t('paymentTerms') ?? "Payment Terms", to: '/payment-terms', minRoles: ['SupervisorAgent', 'CommercialSpecialist'] },
        { title: this?.$t('priceCatalogs') ?? "Price Catalogs", to: '/load-price-catalog', minRoles: ['SupervisorAgent', 'CommercialSpecialist'] },
      ]
    }
  },
  data: () => ({
    dialog: false,
    temp: {},
  }),
  methods: {
    isInRole(roles) { return this.$store.get("auth/isInRole", roles); },
    travel() {
      this.setAll(this.temp.tenant);
      this.dialog = false
    },
    ...call("utils/*"),
    ...call('tenantConfiguration/*'),
  }
}
</script>
